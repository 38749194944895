import Request from './request'
import type { AxiosResponse, Method } from 'axios'
import type { IApi } from 'resources/modules/Http/Contracts/api.ts'

class Api extends Request implements IApi {
    public async request<T = any>(
        method: Method,
        url: string,
        data: object = {},
        headers: object = {}
    ): Promise<AxiosResponse<T>> {
        return await this.axios.request({
            url,
            method,
            data: method === 'get' ? {} : data,
            params: method === 'get' ? data : {},
            headers: {
                ...headers,
                'X-Api-Request': true,
                'X-Requested-With': 'XMLHttpRequest',
            },
        })
    }

    public async get<T = any>(url: string, headers = {}, data = {}): Promise<AxiosResponse<T>> {
        return await this.request('get', url, data, headers)
    }

    public async put<T = any>(url: string, headers = {}, data = {}): Promise<AxiosResponse<T>> {
        return await this.request('put', url, data, headers)
    }

    public async post<T = any>(url: string, data: object, headers = {}): Promise<AxiosResponse<T>> {
        return await this.request('post', url, data, headers)
    }

    public async patch<T = any>(url: string, data: object, headers = {}): Promise<AxiosResponse<T>> {
        return await this.request('patch', url, data, headers)
    }

    public async delete<T = any>(url: string, headers = {}, data = {}): Promise<AxiosResponse<T>> {
        return await this.request('delete', url, data, headers)
    }

    public async retryRequest<T = any>(): Promise<AxiosResponse<T>> {
        if (this.config === undefined) {
            throw new Error('Cannot retry request')
        }

        await this.withoutInterceptors().get('/sanctum/csrf-cookie')

        return await this.axios.request(this.config)
    }
}

export default Api
