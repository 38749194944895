import type { Plugin } from 'vue'
import { hasInjectionContext, inject } from 'vue'
import type { Name } from './Config/names'
import type { Parameters, Route } from './types'
import { createPlugin } from '@shared/helpers.ts'
import Router from '@modules/Router/Routing/router.ts'

const routeSymbol = Symbol('route')
const route: Route = (name: Name, parameters?: Parameters): string => {
    return Router.build(name, parameters)
}

export const createRoute: Plugin = {
    install(app): void {
        createPlugin(app, route, routeSymbol)
    },
}

export function useRoute(): Route {
    return hasInjectionContext() ? inject(routeSymbol, route) : route
}
