import type { ResponseInterceptor } from '@modules/Http/types'
import { type App, type Plugin, createApp, h } from 'vue'
import Component from './Components/Application'
import type { Config } from '@modules/App/types'
import * as Sentry from '@sentry/vue'
import { tap } from 'lodash'
import type { Data } from '@shared/types.ts'
import type { IApplication } from '@modules/App/Contracts/application.ts'
import Application from '@modules/App/Foundation/application.ts'

export const createSentry: Plugin = {
    install(app): void {
        if (import.meta.env.PROD) {
            Sentry.init({
                app,
                dsn: 'https://cadb059df548c857b1413d0b359d120c@o326837.ingest.sentry.io/4505647234678784',
                integrations: [Sentry.replayIntegration(), Sentry.browserTracingIntegration()],
                tracePropagationTargets: [`https://${window.location.host}/api/`],

                // Performance Monitoring
                tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!

                // Session Replay
                replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
                replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
            })
        }
    },
}

export const createApplication = ({ setup, ...props }: Config): void => {
    tap<App>(createApp({ render: () => h(Component, props) }), (app) => {
        setup(app)
    })
}

export const renderPageHandler = (): ResponseInterceptor => {
    return {
        onFulfilled: async (response, next) => {
            if (response.headers['x-render'] === 'true') {
                await Application.handlePage(response.data)
            }

            return next(response)
        },
    }
}

export const useApplication = (): IApplication => {
    return Application
}

export const useProps = (): Data => {
    return Application.props
}

export const usePage = <T>(): T => {
    return Application.props.page
}
