import { type App, type InjectionKey } from 'vue'
import { type IconDefinition } from '@fortawesome/free-brands-svg-icons'
import { type IconPack, library } from '@fortawesome/fontawesome-svg-core'

Object.defineProperty(String.prototype, 'ucFirst', {
    value: function () {
        return this.charAt(0).toUpperCase() + this.slice(1)
    },
    enumerable: false,
})

export const wrap = <T>(value: any): Record<string, T> | T[] => {
    if (value instanceof Array || value instanceof Object) {
        return value
    }

    return [value]
}

export const isset = (data: object, key: string): boolean => {
    return Object.prototype.hasOwnProperty.call(data, key)
}

export const hrefToUrl = (href: string): URL => {
    return new URL(href.toString(), window.location.toString())
}

export const createPlugin = (app: App, instance: any, identifier: InjectionKey<string>): void => {
    app.provide(identifier, instance)

    if (identifier.description !== undefined) {
        app.config.globalProperties[identifier.description] = instance
    }
}

export const ucFirst = (value: string): string => {
    return value.charAt(0).toUpperCase() + value.slice(1)
}

export const createIcons = (icons: (IconDefinition | IconPack)[]): void => {
    library.add(...icons)
}

export const value = <T = any>(value: T | (() => T)): T => {
    if (value instanceof Function) {
        return value()
    }

    return value
}
