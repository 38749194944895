import type { ResponseInterceptor } from '@modules/Http/types'
import { isset } from '@shared/helpers'
import application from '@modules/App/Foundation/application.ts'
import User from '@modules/Auth/user.ts'
import { reactive } from 'vue'
import type { InstanceOfUser } from '@modules/Auth/types.ts'

const makeAuth = (): InstanceOfUser => {
    return application.make<InstanceOfUser>('auth', () => reactive(new User()))
}

export const authHandler = (): ResponseInterceptor => {
    const auth = makeAuth()

    return {
        onFulfilled: (response, next) => {
            const props = response.data.component?.props

            if (typeof props === 'object' && isset(props, 'user')) {
                if ('PushManager' in window && !auth.check) {
                    initPush()
                }

                auth.setUser(props.user)
            }

            return next(response)
        },
    }
}

export const useAuth = (): InstanceOfUser => {
    return makeAuth()
}
