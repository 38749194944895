<template>
    <transition
        mode="out-in"
        enter-from-class="opacity-0"
        enter-active-class="transform transition-all duration-400"
        leave-to-class="opacity-0"
        leave-active-class="transform transition-all duration-400"
    >
        <template v-if="application.isChanging && application.changingViewSection === section">
            <component
                :is="application.loadingComponent"
                v-if="application.isLoading && application.loadingComponent"
                key="loading"
                v-bind="$attrs"
            />
        </template>
        <template v-else>
            <slot key="content" />
        </template>
    </transition>
</template>

<script setup lang="ts">
import { useApplication } from '@modules/App'

import type { ViewSection } from '@modules/App/Contracts/application.ts'

type Props = {
    section: ViewSection
}

defineProps<Props>()
defineOptions({
    inheritAttrs: false,
})

const application = useApplication()
</script>
