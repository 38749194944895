import { wrap } from '@shared/helpers.ts'
import type { Parameters } from '@modules/Router/types.ts'
import type { IRoute } from '@modules/Router/Contracts/route.ts'
import type { Parameter, RouteValues, Segments, WrappedParameters } from '@modules/Router/Contracts/builder.ts'
import type { Options } from '@modules/Router/Contracts/router.ts'

class Builder {
    private readonly route: IRoute
    private readonly options: Options

    public constructor(route: IRoute, options: Options) {
        this.route = route
        this.options = options
    }

    private get template(): string {
        const uri = this.route.uri
        const domain = this.options.domain
        const scheme = this.options.scheme

        return `${scheme}://${domain}/${uri}`.replace(/\/+$/, '')
    }

    private get segments(): Segments {
        return (
            this.template.match(/\{([^?}]+)(\??)\}/g)?.map((segment) => ({
                name: segment.replace(/\{|\??\}/g, ''),
                required: !segment.endsWith('?}'),
            })) ?? []
        )
    }

    private compile(values: RouteValues): string {
        if (this.segments.length === 0) {
            return this.template
        }

        return this.template
            .replace(/\{([^?}]+)(\??)\}/g, (_, segment, optional) => {
                if (optional.length === 0 && [null, undefined].includes(values[segment])) {
                    throw new Error(`'${segment}' parameter is required for route [${this.route.name}]`)
                }

                return encodeURIComponent(values[segment] ?? '')
            })
            .replace(/\/+$/, '')
    }

    private resolveParameters(parameters: WrappedParameters): RouteValues {
        const segments: Segments = this.segments.filter(({ name }) => this.options.defaults[name] === undefined)
        const defaults: RouteValues = this.segments.reduce(
            (result, { name }) => ({ ...result, [name]: this.options.defaults[name] }),
            {}
        )

        if (Array.isArray(parameters)) {
            parameters = parameters.reduce((result, current, i) => ({ ...result, [segments[i].name]: current }), {})
        }

        return { ...defaults, ...parameters }
    }

    public toSting(parameters: Parameters = {}): string {
        return this.compile(this.resolveParameters(wrap<Parameter>(parameters)))
    }
}

export default Builder
