import Api from './Request/api'
import type { Plugin } from 'vue'
import type { IApi } from 'resources/modules/Http/Contracts/api.ts'
import type { Interceptors, ResponseInterceptor } from './types'

const api = new Api()

export function csrfTokenHandler(): ResponseInterceptor {
    return {
        onRejected: (error, next) => {
            if (error.response.status === 419) {
                return api.retryRequest()
            }

            return next(error)
        },
    }
}

export const createApi: Plugin<Interceptors> = {
    install(app, interceptors) {
        app.config.globalProperties.$api = api.setInterceptors(interceptors)
    },
}

export function useApi(): IApi {
    return api
}
