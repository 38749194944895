import type { IUser, UserResponse } from '@modules/Auth/types.ts'

class User implements IUser {
    private data: UserResponse | null = null

    public get user(): UserResponse {
        if (this.data === null) {
            throw new Error('User is not authenticated')
        }

        return this.data
    }

    public get check(): boolean {
        return this.data !== null
    }

    public get isAdmin(): boolean {
        return this.data?.is_admin ?? false
    }

    public setUser(user: UserResponse) {
        this.data = user
    }

    public logout() {
        this.data = null
    }
}

export default User
