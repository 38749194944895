import Container from '@modules/App/Foundation/container.ts'
import type { IApi } from '@modules/Http/Contracts/api.ts'
import type { Pages } from '@modules/App/types.ts'
import { type Component, h, markRaw, reactive } from 'vue'
import { useApi } from '@modules/Http'
import { isNil, tap } from 'lodash'
import { useHead } from 'unhead'
import type { AxiosResponse, Method } from 'axios'
import { hrefToUrl } from '@shared/helpers.ts'
import type { Data } from '@shared/types.ts'
import type {
    IApplication,
    Page,
    PageResponse,
    SkeletonType,
    View,
    ViewLoader,
    ViewSection,
} from '@modules/App/Contracts/application.ts'
import Router from '@modules/Router/Routing/router.ts'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'

class Application extends Container implements IApplication {
    public version = '0.0.0'
    public route?: string = undefined

    private readonly api: IApi
    private readonly page: Page = {
        title: undefined,
        view: markRaw({
            props: {},
            component: h('div'),
        }),
        resolver: undefined,
    }
    private readonly view: View = {
        loader: markRaw({
            handle: undefined,
            component: undefined,
            skeletons: {},
        }),

        loading: false,
        changing: false,
        section: 'root',
        skeleton: undefined,
    }

    public constructor() {
        super()

        this.api = useApi()
    }

    public get props() {
        return this.page.view.props
    }

    public get component() {
        return markRaw(this.page.view.component)
    }

    public get isLoading() {
        return this.view.loading
    }

    public get isChanging() {
        return this.view.changing
    }

    public get changingViewSection() {
        return this.view.section
    }

    public get loadingComponent() {
        if (!isNil(this.view.skeleton)) {
            const skeleton = this.view.loader.skeletons[`./Views/Skeletons/${this.view.skeleton}Skeleton.vue`]

            if (!isNil(skeleton)) {
                return markRaw(skeleton.default ?? skeleton)
            }
        }

        if (isNil(this.view.loader.component)) {
            return undefined
        }

        return markRaw(this.view.loader.component)
    }

    public setPages(pages: Pages) {
        return tap(this, () => {
            this.page.resolver = async (name: string) => {
                return resolvePageComponent(`./Views/Pages/${name}.vue`, pages)
            }
        })
    }

    public setTitle(value: string) {
        return tap(this, () => {
            this.page.title = value
        })
    }

    public setLoader(value: ViewLoader) {
        return tap(this, () => {
            this.view.loader = markRaw(value)
        })
    }

    public setSkeleton(value?: SkeletonType) {
        return tap(this, () => {
            this.view.skeleton = value
        })
    }

    public setChangingViewSection(value?: ViewSection) {
        return tap(this, () => {
            this.view.section = value ?? 'root'
        })
    }

    public changeTitle(value?: string) {
        return tap(this, () => {
            useHead({ title: value ?? this.page.title })
        })
    }

    public async loadPage(route: string, method: Method = 'get', data: object = {}): Promise<AxiosResponse> {
        return await tap(this.api, () => {
            this.guessSkeleton(route).startViewChanging()
        }).request(method, route, data)
    }

    public async handlePage({ component, route, title, version }: PageResponse, replace = false): Promise<any> {
        if (typeof this.page.resolver !== 'function') {
            throw new Error('You must provide a resolver')
        }

        if (this.route === route) {
            throw new Error('Is same route')
        }

        await this.page.resolver(component.name).then((module) => {
            const page = module.default ?? module

            return tap(this, () => {
                this.version = version

                this.changePage(page, component.props).changeTitle(title)
            }).changeRoute(route, replace)
        })

        // Something weird with javascript, but this fixes the issue.
        // Because the function is too early in the call stack of javascript
        // with this solution it's pushed to the call stack when is clear.
        void Promise.resolve().then(() => {
            this.stopViewChanging()
        })
    }

    public handleRoute(route?: string) {
        if (typeof route === 'string') {
            this.loadPage(route).catch(() => {
                this.stopViewChanging()
            })
        } else {
            this.replaceRoute(hrefToUrl('').href)
        }

        return this
    }

    public startViewChanging() {
        this.view.changing = true

        if (typeof this.view.loader.handle === 'function') {
            this.view.loader.handle()
        }

        return this
    }

    public startViewLoading() {
        return tap(this, () => {
            this.view.loading = true
        })
    }

    public stopViewChanging() {
        if (typeof this.view.loader.handle === 'function') {
            this.view.loader.handle.cancel()
        }

        this.view.loading = false
        this.view.changing = false
        this.view.skeleton = undefined

        return this
    }

    private changePage(component: Component, props: Data) {
        return tap(this, () => {
            this.page.view = markRaw({ component, props })
        })
    }

    private changeRoute(route: string, replace = false) {
        return tap(this, () => {
            this.route = route

            if (replace || this.isSameRoute(route)) {
                this.replaceRoute(route)
            } else {
                this.pushRoute(route)
            }
        })
    }

    private pushRoute(value: string) {
        return tap(this, () => {
            window.history.pushState(this.routeData(value), '', value)
        })
    }

    private replaceRoute(value: string) {
        return tap(this, () => {
            window.history.replaceState(this.routeData(value), '', value)
        })
    }

    private routeData(route: string) {
        return {
            route: route,
            section: this.view.section,
        }
    }

    private isSameRoute(route: string) {
        return hrefToUrl(route).href === window.location.href
    }

    private guessSkeleton(route: string) {
        return tap(this, () => {
            let uri = hrefToUrl(route).pathname

            if (uri.length > 1) {
                uri = uri.slice(1)
            }

            this.setSkeleton(Router.skeleton(uri))
        })
    }
}

export default reactive(new Application())
