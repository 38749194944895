import { tap } from 'lodash'
import type { IRoute } from '@modules/Router/Contracts/route.ts'
import config from '@modules/Router/Config/routes.ts'
import Route from '@modules/Router/Routing/route.ts'
import type { Name } from '@modules/Router/Config/names.ts'
import Builder from '@modules/Router/Routing/builder.ts'
import type { Parameters } from '@modules/Router/types.ts'
import type { Options } from '@modules/Router/Contracts/router.ts'
import type { SkeletonType } from '@modules/App/Contracts/application.ts'

class Router {
    private readonly options: Options
    private readonly items: Record<string, IRoute> = {}

    public constructor() {
        this.options = {
            domain: '{domain}',
            scheme: config.scheme,
            defaults: {
                ...config.defaults,
                domain: window.location.host,
            },
        }

        config.routes.forEach((route) => {
            this.items[route.name] = new Route(route)
        })
    }

    public build(name: Name, parameters?: Parameters) {
        const route = tap(this.items[name], (route) => {
            if (route === undefined) {
                throw new Error(`route '${name}' is not in the route list.`)
            }
        })

        return new Builder(route, this.options).toSting(parameters)
    }

    public skeleton(uri: string): SkeletonType | undefined {
        return Object.values(this.items).find((route) => {
            return route.matches(uri) && route.view !== undefined
        })?.view
    }
}

export default new Router()
