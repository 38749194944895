import { isNil, tap } from 'lodash'
import type { Data } from '@shared/types.ts'
import type { IContainer } from '@modules/App/Contracts/container.ts'

class Container implements IContainer {
    private readonly instances: Data = {}

    public has(identifier: string) {
        return !isNil(this.instances[identifier])
    }

    public get<T = any>(identifier: string): T {
        return this.instances[identifier]
    }

    public make<T = any>(identifier: string, value: any): T {
        if (this.has(identifier)) {
            return this.get(identifier)
        }

        return tap(this.resolve(value), (result) => {
            this.instances[identifier] = result
        })
    }

    public forget(identifier: string) {
        delete this.instances[identifier]
    }

    private resolve(value: any): any {
        if (typeof value === 'function') {
            return value()
        }

        return value
    }
}

export default Container
