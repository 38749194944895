import type { Method } from 'axios'
import type { IRoute } from '@modules/Router/Contracts/route.ts'
import type { RouteConfig } from '@modules/Router/Contracts/config.ts'
import type { SkeletonType } from '@modules/App/Contracts/application.ts'
import type { Name } from '@modules/Router/Config/names.ts'

class Route implements IRoute {
    private readonly route: RouteConfig

    public constructor(route: RouteConfig) {
        this.route = route
    }

    public get name(): Name {
        return this.route.name
    }

    public get uri(): string {
        return this.route.uri
    }

    public get domain(): string | undefined {
        return this.route.domain
    }

    public get methods(): Method[] {
        return this.route.methods
    }

    public get view(): SkeletonType | undefined {
        return this.route.view
    }

    public matches(uri: string): boolean {
        return this.getPattern().test(uri)
    }

    private getPattern(): RegExp {
        const pattern = this.uri
            .replace(/{[a-zA-Z0-9_-]+}/g, '([a-zA-Z0-9_-]+)')
            .replace(/\/{[a-zA-Z0-9_\-?]+}/g, '/?([a-zA-Z0-9_-]*)')

        return new RegExp('^' + pattern + '$')
    }
}

export default Route
