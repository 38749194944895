import axios from 'axios'
import type { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import type { Interceptor, InterceptorIterator, Interceptors, Next } from '../types'
import type { IRequest } from 'resources/modules/Http/Contracts/request.ts'

type Type = 'request' | 'response'
type Method = 'onFulfilled' | 'onRejected'

class Request implements IRequest {
    private readonly http: AxiosInstance

    private interceptors: Interceptors = {
        request: {},
        response: {},
    }

    private runWithoutInterceptors = false

    protected config?: InternalAxiosRequestConfig

    public constructor() {
        this.http = axios.create()

        this.http.interceptors.request.use(
            (config) =>
                this.setConfig(
                    this.runFulfilledInterceptors<InternalAxiosRequestConfig>(this.requestInterceptors, config)
                ),
            (error) => {
                try {
                    return this.reset<any>(this.runRejectedInterceptors(this.requestInterceptors, error))
                } catch (_) {
                    return this.reset<any>(Promise.reject(error))
                }
            }
        )

        this.http.interceptors.response.use(
            (response) =>
                this.reset<AxiosResponse>(
                    this.runFulfilledInterceptors<AxiosResponse>(this.responseInterceptors, response)
                ),
            (error) => {
                try {
                    return this.reset<any>(this.runRejectedInterceptors(this.responseInterceptors, error))
                } catch (_) {
                    return this.reset<any>(Promise.reject(error))
                }
            }
        )
    }

    public get axios(): AxiosInstance {
        return this.http
    }

    public get requestInterceptors(): InterceptorIterator<InternalAxiosRequestConfig> {
        return this.getInterceptors<InternalAxiosRequestConfig>('request')
    }

    public get responseInterceptors(): InterceptorIterator<AxiosResponse> {
        return this.getInterceptors<AxiosResponse>('response')
    }

    public setInterceptors(interceptors: Interceptors): this {
        this.interceptors = interceptors

        return this
    }

    public withoutInterceptors(): this {
        this.runWithoutInterceptors = true

        return this
    }

    private getInterceptors<T>(type: Type): InterceptorIterator<T> {
        const interceptors = this.interceptors[type]

        return interceptors !== undefined
            ? Object.values(interceptors)
                  .reverse()
                  .map((callbacks) => {
                      if (typeof callbacks === 'function') {
                          callbacks = callbacks()
                      }

                      return callbacks
                  })
            : []
    }

    private runInterceptor<T>(method: Method, interceptors: InterceptorIterator<T>, passable: T): T {
        if (!this.runWithoutInterceptors) {
            if (interceptors.length === 0) {
                return passable
            }

            return interceptors.reduce(
                (stack: Next<T>, interceptor: Interceptor<T>) => (passable: T) => {
                    const handle = interceptor[method]

                    return typeof handle === 'function' ? handle(passable, stack) : stack(passable)
                },
                (passable: T) => passable
            )(passable)
        }

        return passable
    }

    private runFulfilledInterceptors<T>(interceptors: InterceptorIterator<T>, passable: T): T {
        return this.runInterceptor<T>('onFulfilled', interceptors, passable)
    }

    private async runRejectedInterceptors(interceptors: InterceptorIterator<any>, passable: any): Promise<any> {
        passable = this.runInterceptor<any>('onRejected', interceptors, passable)

        if (typeof passable.then === 'function') {
            return passable
        }

        return await Promise.reject(passable)
    }

    private setConfig(config: InternalAxiosRequestConfig): InternalAxiosRequestConfig {
        if (!(config.url?.endsWith('sanctum/csrf-cookie') ?? false)) {
            this.config = config
        }

        return config
    }

    private reset<T>(passable: T): T {
        this.runWithoutInterceptors = false

        return passable
    }
}

export default Request
