import { debounce } from 'lodash'
import type { PropType, VNode } from 'vue'
import RouterView from './Router/RouterView.vue'
import { createCommentVNode, defineComponent, h } from 'vue'
import { useApplication } from '../index.ts'
import type { LoaderConfig, Pages } from '../types'

export default defineComponent({
    name: 'App',

    props: {
        title: {
            type: String,
            default: '',
        },
        pages: {
            type: Object as PropType<Pages>,
            required: true,
        },
        loader: {
            type: Object as PropType<LoaderConfig>,
            default: (): LoaderConfig => ({
                delay: 1000,
                component: h('div'),
            }),
        },
    },

    setup: ({ title, pages, loader }) => {
        const application = useApplication()
        const page = (): VNode => h('div', h(application.component, { key: application.props.key }))

        application.setTitle(title)
        application.setPages(pages)
        application.setLoader({
            component: loader.component,
            handle: debounce(() => {
                application.startViewLoading()
            }, loader.delay),
            skeletons: loader.skeletons ?? {},
        })

        window.addEventListener('load', () => {
            application.loadPage(window.location.href).catch((error) => {
                console.error(error)
            })
        })
        window.addEventListener('popstate', ({ state }) => {
            application.setChangingViewSection(state.section).handleRoute(state.route)
        })

        return () => [createCommentVNode(' Application '), h(RouterView, { section: 'root', class: 'h-screen' }, page)]
    },
})
