import type { Config } from '@modules/Router/Contracts/config.ts'

/* eslint-disable */
const config: Config = {
    "domain": "rankingthegems.nl",
    "scheme": "https",
    "defaults": {
        "mainDomain": "rankingthegems.test",
        "groupDomain": "group.rankingthegems.test"
    },
    "routes": [
        {
            "uri": "sanctum/csrf-cookie",
            "name": "sanctum.csrf-cookie",
            "methods": [
                "GET",
                "HEAD"
            ]
        },
        {
            "uri": "manifest.json",
            "name": "laravelpwa.manifest",
            "methods": [
                "GET",
                "HEAD"
            ]
        },
        {
            "uri": "offline",
            "name": "laravelpwa.",
            "methods": [
                "GET",
                "HEAD"
            ]
        },
        {
            "uri": "_ignition/health-check",
            "name": "ignition.healthCheck",
            "methods": [
                "GET",
                "HEAD"
            ]
        },
        {
            "uri": "_ignition/execute-solution",
            "name": "ignition.executeSolution",
            "methods": [
                "POST"
            ]
        },
        {
            "uri": "_ignition/update-config",
            "name": "ignition.updateConfig",
            "methods": [
                "POST"
            ]
        },
        {
            "uri": "/",
            "name": "main.dashboard",
            "methods": [
                "GET",
                "HEAD"
            ]
        },
        {
            "uri": "api/profile/update",
            "name": "group.api.profile.update",
            "methods": [
                "PATCH"
            ]
        },
        {
            "uri": "api/profile/update/password",
            "name": "group.api.profile.update.password",
            "methods": [
                "PATCH"
            ]
        },
        {
            "uri": "api/profile/disconnect/{type}",
            "name": "group.api.profile.disconnect",
            "methods": [
                "GET",
                "HEAD"
            ]
        },
        {
            "uri": "api/gems/{gem}/rating/submit",
            "name": "group.api.gem.rating.submit",
            "methods": [
                "POST"
            ]
        },
        {
            "uri": "api/{group}/invites",
            "name": "group.api.invite",
            "methods": [
                "POST"
            ]
        },
        {
            "uri": "api/{group}/feed/layout/{type}",
            "name": "group.api.feed",
            "methods": [
                "GET",
                "HEAD"
            ]
        },
        {
            "uri": "api/{group}/feed/add/{gem}",
            "name": "group.api.feed.add",
            "methods": [
                "GET",
                "HEAD"
            ]
        },
        {
            "uri": "api/{group}/feed/search",
            "name": "group.api.feed.search",
            "methods": [
                "GET",
                "HEAD"
            ]
        },
        {
            "uri": "api/{group}/feed/submit",
            "name": "group.api.feed.submit",
            "methods": [
                "POST"
            ]
        },
        {
            "uri": "api/{group}/feed/spotify/play",
            "name": "group.api.feed.spotify.play",
            "methods": [
                "POST"
            ]
        },
        {
            "uri": "api/{group}/feed/spotify/devices",
            "name": "group.api.feed.spotify.devices",
            "methods": [
                "GET",
                "HEAD"
            ]
        },
        {
            "uri": "api/{group}/feed/spotify/device/change",
            "name": "group.api.feed.spotify.device.change",
            "methods": [
                "PUT"
            ]
        },
        {
            "uri": "api/{group}/settings/preferences/general",
            "name": "group.api.settings.preferences.general",
            "methods": [
                "PATCH"
            ]
        },
        {
            "uri": "api/{group}/settings/preferences/ratings",
            "name": "group.api.settings.preferences.ratings",
            "methods": [
                "PATCH"
            ]
        },
        {
            "uri": "api/{group}/settings/preferences/schedule",
            "name": "group.api.settings.preferences.schedule",
            "methods": [
                "PATCH"
            ]
        },
        {
            "uri": "api/{group}/settings/members/table/items",
            "name": "group.api.settings.members.table.items",
            "methods": [
                "GET",
                "HEAD"
            ]
        },
        {
            "uri": "api/{group}/settings/members/{member}/update",
            "name": "group.api.settings.member.update",
            "methods": [
                "PATCH"
            ]
        },
        {
            "uri": "api/{group}/settings/members/{member}/revoke",
            "name": "group.api.settings.member.revoke",
            "methods": [
                "DELETE"
            ]
        },
        {
            "uri": "api/{group}/settings/members/{member}/resend/invite",
            "name": "group.api.settings.member.resend.invite",
            "methods": [
                "PUT"
            ]
        },
        {
            "uri": "api/{group}/settings/events/table/items",
            "name": "group.api.settings.events.table.items",
            "methods": [
                "GET",
                "HEAD"
            ]
        },
        {
            "uri": "api/{group}/settings/events/past/table/items",
            "name": "group.api.settings.events.past.table.items",
            "methods": [
                "GET",
                "HEAD"
            ]
        },
        {
            "uri": "api/{group}/settings/events/create",
            "name": "group.api.settings.event.create",
            "methods": [
                "POST"
            ]
        },
        {
            "uri": "api/{group}/settings/events/{event}/update",
            "name": "group.api.settings.event.update",
            "methods": [
                "PATCH"
            ]
        },
        {
            "uri": "api/{group}/settings/events/{event}/cancel",
            "name": "group.api.settings.event.cancel",
            "methods": [
                "DELETE"
            ]
        },
        {
            "uri": "api/login",
            "name": "group.api.auth.attempt",
            "methods": [
                "POST"
            ]
        },
        {
            "uri": "api/register",
            "name": "group.api.auth.register",
            "methods": [
                "POST"
            ]
        },
        {
            "uri": "api/forgot-password",
            "name": "group.api.password.email",
            "methods": [
                "POST"
            ]
        },
        {
            "uri": "api/reset-password",
            "name": "group.api.password.store",
            "methods": [
                "POST"
            ]
        },
        {
            "uri": "api/logout",
            "name": "group.api.auth.logout",
            "methods": [
                "POST"
            ]
        },
        {
            "uri": "api/resend/email/verification",
            "name": "group.api.auth.verification.resend",
            "methods": [
                "POST"
            ]
        },
        {
            "uri": "api/auth/push",
            "name": "group.api.auth.push",
            "methods": [
                "POST"
            ]
        },
        {
            "uri": "/",
            "name": "group.all",
            "methods": [
                "GET",
                "HEAD"
            ],
            "view": "Dashboard/Show"
        },
        {
            "uri": "invitation/{group}/{user}/{hash}",
            "name": "group.invitation",
            "methods": [
                "GET",
                "HEAD"
            ]
        },
        {
            "uri": "profile/edit",
            "name": "group.profile.edit",
            "methods": [
                "GET",
                "HEAD"
            ],
            "view": "Profile/Edit"
        },
        {
            "uri": "{group}/statistics",
            "name": "group.statistics",
            "methods": [
                "GET",
                "HEAD"
            ],
            "view": "Statistics/Show"
        },
        {
            "uri": "{group}/feed",
            "name": "group.show",
            "methods": [
                "GET",
                "HEAD"
            ],
            "view": "Feed/Show"
        },
        {
            "uri": "{group}/settings",
            "name": "group.settings.redirect",
            "methods": [
                "GET",
                "HEAD"
            ]
        },
        {
            "uri": "{group}/settings/preferences",
            "name": "group.settings.preferences",
            "methods": [
                "GET",
                "HEAD"
            ]
        },
        {
            "uri": "{group}/settings/members",
            "name": "group.settings.members",
            "methods": [
                "GET",
                "HEAD"
            ]
        },
        {
            "uri": "{group}/settings/subscription",
            "name": "group.settings.subscription",
            "methods": [
                "GET",
                "HEAD"
            ]
        },
        {
            "uri": "{group}/settings/events",
            "name": "group.settings.events",
            "methods": [
                "GET",
                "HEAD"
            ]
        },
        {
            "uri": "login",
            "name": "group.auth.login",
            "methods": [
                "GET",
                "HEAD"
            ],
            "view": "Auth/Login"
        },
        {
            "uri": "register",
            "name": "group.auth.register",
            "methods": [
                "GET",
                "HEAD"
            ]
        },
        {
            "uri": "forgot-password",
            "name": "group.password.request",
            "methods": [
                "GET",
                "HEAD"
            ],
            "view": "Auth/ForgotPassword"
        },
        {
            "uri": "reset-password/{token}",
            "name": "group.password.reset",
            "methods": [
                "GET",
                "HEAD"
            ]
        },
        {
            "uri": "email/verify/{id}/{hash}",
            "name": "group.auth.verification.verify",
            "methods": [
                "GET",
                "HEAD"
            ]
        },
        {
            "uri": "pwa",
            "name": "group.pwa",
            "methods": [
                "GET",
                "HEAD"
            ]
        },
        {
            "uri": "pwa/manifest.json",
            "name": "group.pwa.manifest",
            "methods": [
                "GET",
                "HEAD"
            ]
        },
        {
            "uri": "connects/spotify/callback",
            "name": "group.connect.spotify.callback",
            "methods": [
                "GET",
                "HEAD"
            ]
        },
        {
            "uri": "api/broadcasting/auth",
            "name": "group.broadcast.auth",
            "methods": [
                "GET",
                "POST",
                "HEAD"
            ]
        }
    ]
}
/* eslint-enable */

export default config
