import { createPinia, type PiniaPluginContext, type Store } from 'pinia'
import { type Plugin } from 'vue'

export const createStore: Plugin<(store: Store) => void> = {
    install(app, callback) {
        const pinia = createPinia().use(({ store }: PiniaPluginContext) => {
            callback(store)

            store.$destroy = () => {
                store.$dispose()

                Reflect.deleteProperty(pinia.state.value, store.$id)
            }
        })

        app.use(pinia)
    },
}
